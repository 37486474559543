import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import MovieDetail from '../components/moviedetails/MovieDetail'
import { useParams} from "react-router-dom";
import PriceMovie from "../components/moviedetails/PriceMovie";
import cn from "classnames";
import s from "../components/Afisha/Afisha.module.css";


const MovieDetails = (props) => {
    let Films=[...props.Films]
    const { id } = useParams();


    const movie = Films.find((film) => film.id.toString() === id.toString());
    const { nameFilm, timeFilm, trailer, filmSessions,
        urlPicture, ProductionYear, yearLimit,description,
        genre,formatAll,director,playRole, country } = movie;

    const idToFind = id.toString();
    const film = Films.find(film => film.id.toString() === idToFind);
        const sessions = film.filmSessions.map(session => {
            return {
                dataSession: session.dataSession,
                data1: DateFormatTimeAnd(session.dataSession)[0],
                data2: DateFormatTimeAnd(session.dataSession)[1],
                format: session.format,
                place:session.place,
            };
        }).sort((a, b) => {
            return new Date(a.dataSession) - new Date(b.dataSession);
        });
        // console.log(sessions);

    let ses = sessions.reduce((acc, cur) => {
        let index = acc.findIndex(obj => obj.data1 === cur.data1);
        if (index === -1) {
            acc.push({ dataSession:cur.dataSession,data1: cur.data1, data2: cur.data2,place:cur.place});
        } else {
            acc[index].data2 += `, ${cur.data2}`;
        }
        return acc;
    }, []);

    const today = new Date().toISOString().slice(0, 10);
    const sortedData = ses.filter(obj => obj.dataSession >= today).sort((a, b) => a.dataSession.localeCompare(b.dataSession));
    // console.log(sortedData);





//функция число месяца заменяет словами--------------
    function getMonthName(x) {
        let d=parseInt(x.toString());
        const monthNames = [
            'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
            'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
        ];
        return monthNames[d];
    }
    function DateFormatTimeAnd(x){
        const dateStr = x;
        const date = new Date(dateStr);
        const day = date.getDate();
        const month = getMonthName(date.getMonth());
        const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const result = [`${day} ${month}`, time];
         return result;

    }
let Rf=1;
    if (movie.filmSessions.length === 0) {
       Rf=0;
    }

//-----------------------------------------------------


  return (
    <div>
        <Header/>
        <main>
            <MovieDetail movie={movie}/>
           <div    className={cn(Rf===0 && s.none)}>
               <PriceMovie ses={sortedData}/>
           </div>
        </main>
        <Footer/>
    </div>
  )
}

export default MovieDetails