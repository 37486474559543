import React from 'react';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <footer>
        <div className="footer-top-wrap">
          <div className="container">
            <div className="footer-menu-wrap">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <div className="footer-logo">
                    <Link to="/"><img src="/img/logo/logo.png" alt="" /></Link>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="footer-menu">
                    <nav>
                      <ul className="navigation">
                        <li className=""><Link to="/"> ГЛАВНАЯ </Link></li>
                        <li className="menu-item-has-children"><Link to="/movies-now"> ФИЛЬМЫ </Link></li>
                        <li className=""><Link to="/contact">КОНТАКТЫ</Link></li>
                        <li className=""><Link to="/pricing">РЕПЕРТУАР </Link></li>
                      </ul>
                    </nav>

                  </div>
                </div>

              </div>
            </div>

            <div className="footer-quick-link-wrap">
              <div className="row align-items-center">


                <div className="col-md-7 ">
                  <div className="quick-link-list">
                    <ul>
                      <li><a href="https://www.kultura.by/" target="_blank"> <img src="/img/mkrb.gif" width={150} height={50} alt="" /></a></li>
                      <li><a href="http://kultura.pruzhany.by/" target="_blank"> <img src="/img/logoOtdelKultury.png" width={150} height={50} alt="Отдел культуры Пружанского райисполкома" /></a></li>
                      <li><a href="https://belarusfilm.by/" target="_blank"> <img src="/img/logoS.svg" width={150} height={50} alt="" /></a></li>
                      <li><a href="https://kinobrest.by/" target="_blank"> <img src="/img/siteLogoBrestKino.png" width={90} height={40} alt="" /></a></li>

                    </ul>
                  </div>
                </div>


                <div className="col-md-5">
                  <div className="footer-social">
                    <ul className="clearfix">
                      <li><a href="https://www.instagram.com/kino_pruzhany/" target="_blank"> <img src="/img/instagram.png"  width={40} height={40} alt="" /></a></li>
                      <li><a href="https://t.me/KinoPruzhany" target="_blank"> <img src="/img/telegram.png"  width={33} height={33} alt="" /></a></li>
                      <li><a href="https://vk.com/pruzhany.sputnik" target="_blank"> <img src="/img/vk.png"  width={35} height={35} alt="" /></a></li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        {/*<div className="copyright-wrap">*/}
        {/*  <div className="container">*/}
        {/*    <div className="row">*/}
        {/*      <div className="col-lg-6 col-md-6">*/}
        {/*        <div className="copyright-text">*/}
        {/*          <p>Copyright © 2024 <a href="/#">spadaroznik.by</a></p>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      /!*<div className="col-lg-6 col-md-6">*!/*/}
        {/*      /!*  <div className="payment-method-img text-center text-md-right">*!/*/}
        {/*      /!*    /!*<img src="/img/images/card_img.png" alt="img" />*!/*!/*/}
        {/*      /!*  </div>*!/*/}
        {/*      /!*</div>*!/*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </footer>
  )
}

export default Footer