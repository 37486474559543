import {BrowserRouter, Route, Router, Routes} from 'react-router-dom';
import Homeone from './pages/Homeone';
import MovieDetails from './pages/MovieDetails';
import { useEffect} from "react"
import $ from "jquery";
import ContactPage from './pages/ContactPage';
import RaspisanieText from "./pages/RaspisanieText";
import NotPage from "./pages/NotPage";
import ScrollToTop from "./components/ScrollToTop";
import MoviesNow from "./pages/MoviesNow";
import MoviesSoon from "./pages/MoviesSoon";
import Login from "./pages/Login";
import {useDispatch, useSelector} from "react-redux";
import {getFilms} from "./features/counter/filmsSlice";



function App() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getFilms());
        function preloader() {
            $('#preloader').delay(0).fadeOut();
        }
        $(document).ready(function() {
            preloader();
        });
    }, []);
    const Films = useSelector((state) => state.FilmsReducer.Films)




  return (
      <>
          {Films.length!==0&& <div className="App">
              <BrowserRouter>
                  <ScrollToTop />
                  <Routes>
                      <Route path="*" element={<NotPage />} />
                      {/*<Route path="*" element={<NotPage />} />*/}
                      <Route path="/" element={<Homeone Films={Films}/>} />
                      <Route path="/movies-now" element={<MoviesNow Films={Films}/>} />
                      <Route path="/movies-soon" element={<MoviesSoon Films={Films}/>} />
                      <Route path="/login" element={<Login />} />
                      <Route path="/contact" element={<ContactPage/>} />
                      <Route path="/pricing" element={<RaspisanieText Films={Films}/>} />
                      <Route path="/movie-details/:id" element={<MovieDetails Films={Films}/>} />
                  </Routes>
              </BrowserRouter>


          </div>}

      </>
  );
}

export default App;
