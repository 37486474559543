import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import {Outlet} from "react-router-dom";
const NotPage = () => {
  return (
    <>
    {/*<Header/>*/}
        <main>
        <div>НЕТ СТРАНИЦЫ</div>
        </main>
    {/*<Footer/>*/}

    </>
  )
}

export default NotPage