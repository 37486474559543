import React from 'react';


import Footer from '../components/Footer';
import Header from "../components/Header";
import AfishaSoon from "../components/Afisha/AfishaSoon";


const MoviesSoon = (props) => {
  return (
  <div>

      <Header/>
    <main>
        <AfishaSoon Films={props.Films}/>
    </main>
      <Footer/>
  </div>

  )
}

export default MoviesSoon
