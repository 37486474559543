import React, {useEffect} from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import {useDispatch, useSelector} from "react-redux";
import {getFilms, getFilmsAnons, getGoogleSheetsPush} from "../features/counter/filmsSlice";
import FilmPanelEdit from "../components/login/FilmPanelEdit";
import FilmDelete from "../components/login/FilmDelete";
import FilmsAnonsAll from "../components/login/FilmsAnons";
import NewFilm from "../components/login/NewFilm";
import {SlidesM} from "../components/login/SlidesM";
import LoginIsAut from "../components/login/LoginIsAut";


const Login = () => {

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getFilms());
    }, []);

    const IsAut = useSelector((state) => state.FilmsReducer.IsAut)
    const Films = useSelector((state) => state.FilmsReducer.Films)
    const FilmsGooglePush = useSelector((state) => state.FilmsReducer.GoogleSheetsPush)
    const FilmsAnons = useSelector((state) => state.FilmsReducer.FilmsAnons)




    if((IsAut==="true")&&(FilmsGooglePush!=="")&&(FilmsAnons!=="")){

        return(
            <>
                <Header/>
                <main>
                    <LoginIsAut/>
                    <FilmPanelEdit FilmsGooglePush={FilmsGooglePush} Films={Films}/>
                    <SlidesM Films={Films} FilmsAnons={FilmsAnons}/>
                    <FilmDelete Films={Films}/>
                    <FilmsAnonsAll Films={Films} FilmsAnons={FilmsAnons}/>
                    <NewFilm Films={Films}/>
                </main>
                <Footer/>
            </>
        )

        }else{ return(
        <>
            <Header/>
            <main>
                <LoginIsAut/>
            </main>
            <Footer/>
        </>
    )


    }






}

export default Login